<template>
  <div>
    <div class="content mt-12">
      <div
        class="brq pl-2 mt-5 mb-9"
        :style="`border-left: ${theme.midColor} 6px solid;`"
      >
        <div class="sub-title">精读</div>
      </div>
    </div>
    <div>
      <div
        v-for="(item, index) in passage"
        :key="index"
        :class="`mt-8 mb-9 ${index % 2 === 0 ? 'bgl' : ''}`"
      >
        <div class="content">
          <v-img
            v-if="item.img && !isEmpty(item.img)"
            class="mb-7 rounded"
            height="150px"
            :src="item.img"
          >
            <template v-slot:placeholder>
              <v-row
                style="background-color: #6d789d"
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="page-text">
            <div
              v-ripple
              :class="`rounded word${index === 0 && i === 0 ? ' big' : ''}`"
              @click="searchWord(word)"
              v-for="(word, i) in item.text.split(/\s+/)"
              :key="i"
              v-html="word"
            ></div>
          </div>
          <v-fade-transition>
            <div
              v-show="showExplain"
              class="page-explain mt-9"
              v-html="item.explain"
            ></div>
          </v-fade-transition>
        </div>
      </div>
      <div class="content" :class="showExplain ? 'mt-5' : 'mt-13'">
        <v-divider class="mb-12"></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "@/utils/common";

export default {
  name: "pagePassage",
  props: {
    showExplain: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: Object,
      default: () => ({}),
    },
    passage: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isEmpty,
    searchWord(word) {
      this.$emit("searchWord", word);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 1.5rem;
  text-align: justify;
}
.bgl {
  padding: 2.5rem 0;
  width: 100%;
  background-color: #f8f8f8;
}
.page-explain {
  vertical-align: bottom;
  font-size: 0.9rem;
  letter-spacing: 0.15rem;
  color: #909090;
  line-height: 1.5;
}
.page-text {
  font-size: 1rem;
  vertical-align: bottom;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  line-height: 1;

  .word {
    --webkit-transform: scaleY(1.1);
    transform: scaleY(1.1);
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
    padding-right: 0.7rem;
    word-break: keep-all;
  }
  .big {
    font-size: 1.5em;
    font-weight: 300;
    //opacity: 0.6;
  }
}
</style>
